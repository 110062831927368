<template>
    <div class="card">
        <div class="card-header bg-gray">
            <h3 class="card-title"><i class="fas fa-file-alt mr-2"></i>Cambios estado</h3>
        </div>

        <div class="card-body p-0">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th>{{$t('general.fecha')}}</th>
                        <th>{{$t('general.usuario')}}</th>
                        <th>Estado</th>
                        <th>Observaciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="historial in servicioaux.especialidad_pendiente.seguimiento"
                        :key="historial.id_seguimiento">
                        <td>{{historial.fecha}}</td>
                        <td>{{ historial.usuario}}</td>
                        <td>{{historial.estado}}</td>
                        <td>{{ historial.detalle}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
    export default {
        props: ['servicio','id'],
    data() {
        return {
            servicioaux : {especialidad_pendiente:{seguimiento:{idseguimiento:''}}}
        }
    },
    methods: {
  
    },
    mounted() {
    },

    watch: {
     
       servicio() {
           this.servicioaux = this.servicio;

       }
    },
}
</script>
<style>

</style>
